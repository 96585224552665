<template>	
	<div>
		<b-row class="mb-20">
			<b-col>
				<b-card-code
				    title="My Active Activity"
				    no-body
				    class="active-project pb-10 card-icon-none black-80-child capitalize-head w-40-percent-cl-1"
				    :sub-title="items.length" >
				    <b-table
				      	:sticky-header="stickyHeader"
				      	responsive
				      	:items="items"
				      	:fields="fields"
				      	class="mb-0 cursor-pointer"
				      	show-empty
				      	@row-clicked="activity_Project"
				    >
				    	<template #head(activity_name)="data">
					        <span class="d-block text-left">{{ data.label }}</span>
					    </template>
				      	<template #cell()="data">
				        	<span class="">{{ data.value }}</span>
				      	</template>
					    <template #empty="scope">
					      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
					    </template>
				    </b-table>
			  	</b-card-code>
			</b-col>
		</b-row>
	</div>
</template>
<script>
	import BCardCode from '@core/components/b-card-code/BCardCode.vue'
	import { codeSticky } from '@/views/table/bs-table/code.js'

	export default{
		components: {
		    BCardCode,
	  	},
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
		    	// My activity projects
		      	stickyHeader: true,
		      	fields: [
			        {
			          	key: 'project_name', label: 'Project Name'
			        },
			        {
			        	key: 'activity_name', label: 'Activity Name'
			        }
		      	],
		      	items: [],
		    }
		},
		created(){
			this.fetchActivities();
		},
		methods: {
		  	fetchActivities(){
				this.$store
				.dispatch('project/activityListExpert_dashboard', {})
				.then(response => {
					this.items = response.data.data
					// console.log('Expert: my active project dashboard: ', response.data.data)
				})
				.catch((error) => {
					console.log(error)
				})
			},
			activity_Project(item){
				this.$router.push(`/expert/our-portfolio/activity-listing/activity/${item.activity_id}`)
			},
		}
	};
</script>
<style lang="scss">
	.card table tr > th, .card table tr > td{
		padding-left: 21px;
		padding-right: 21px;
	}
	.card table tr > th{
		font-size: 14px
	}
	h4{
		font-size: 16px !important
	}
</style>